.clear-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Container */
.clear-modal {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  padding: 30px;
  max-width: 450px;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

/* Modal Header */
.clear-modal-header {
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
}

.clear-modal-header h4 {
  margin: 0;
  color: #333333;
  font-size: 20px;
  font-weight: 600;
}

/* Modal Body */
.clear-modal-body {
  padding: 20px 0;
  color: #666666;
  font-size: 16px;
}

/* Modal Footer */
.clear-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.clear-modal-footer .btn {
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
}

.clear-modal-footer .btn-cancel {
  background-color: #d32f2f;
  color: #ffffff;
}

.clear-modal-footer .btn-confirm {
  background-color: #4caf01;
  color: #ffffff;
}


.clear-modal-footer .btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(100, 181, 246, 0.5);
}
