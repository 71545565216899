.agreement {
    flex: 4;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .agreementTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .agreementTitle {
    font-size: 24px;
    font-weight: 600;
    color: #333;
  }
  
  .agreementAddButton {
    width: 120px;
    border: none;
    padding: 8px 12px;
    background-color: #4338ca;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .agreementAddButton:hover {
    background-color: #4338ca;
  }
  
  .agreementBottom {
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .agreementForm {
    display: flex;
    justify-content: space-between;
  }
  
  .agreementFormLeft {
    display: flex;
    flex-direction: column;
    width: 60%;
  }
  
  .formLabel {
    font-size: 16px;
    font-weight: 500;
    color: #555;
    margin-bottom: 8px;
  }
  
  .formInput {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 16px;
    font-size: 14px;
    resize: vertical; /* Added for textarea */
  }
  
  .agreementFormRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 35%;
  }
  
  .agreementButton {
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #4338ca;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .agreementButton:hover {
    background-color: #4338ca;
  }
  
  .ck-editor__editable_inline {
    min-height: 200px;
}
