.user {
  flex: 4;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.userTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #008080;
  padding: 10px 20px;
  border-radius: 8px;
  color: white;
}

.userAddButton {
  width: 100px;
  border: none;
  padding: 10px;
  background-color: #4CAF50; /* Green for visibility */
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.userContainer {
  display: flex;
  flex-direction: column; /* Adjusted for better layout on typical admin panels */
  margin-top: 20px;
}

.userUpdate {
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

.userUpdateTitle {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.userUpdateForm {
  display: flex;
  flex-direction: column;
}

.userUpdateItem {
  margin-bottom: 20px;
}

.userUpdateItem > label {
  display: block;
  margin-bottom: 8px;
  color: #666;
}

.userUpdateInput, .userUpdateTextarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.userUpdateTextarea {
  resize: vertical; /* Allows vertical resizing */
}

.userUpdateButton {
  width: 100%;
  padding: 10px;
  background-color: #0056b3;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-weight: bold;
}

.whatWeNeedContainer {
  margin-top: 20px;
}

.whatWeNeedTitle {
  font-size: 20px;
  color: #333;
}

.whatWeNeedList {
  list-style-type: none;
  padding: 0;
}

.whatWeNeedList li {
  margin-bottom: 5px;
  font-size: 16px;
}


/* card.css */
/* card.css */
.strategyUpdate {
  flex: 4;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
}

.strategyTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.strategyTitle {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.strategyAddButton {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.strategyBottom {
  width: 100%;
  max-width: 800px;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.strategyForm {
  display: flex;
  flex-direction: column;
}

.strategyFormLeft {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.strategyFormLeft label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.formInput,
.formSelect {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.strategyFormRight {
  display: flex;
  justify-content: flex-end;
}

.strategyButton {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.packageDetails {
  margin-top: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.packageDetails h3 {
  margin-top: 0;
  color: #333;
}

.packageDetails p {
  margin-bottom: 5px;
  color: #666;
}
.dropdown {
  position: relative;
  margin-bottom: 15px;
}

.dropdown-selected {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  display: none;
}

.dropdown-options.show {
  display: block;
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #f5f5f5;
}