.sidebar {
  flex: 1;
  height: calc(100vh - 50px);
  background-color: white;
  position: sticky;
  top: 50px;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}



.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebarList .sidebarListItem.active {
  background-color: #ddd;
  color: #333;
}


.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}

.paragraphText{
  font-size: 15px;
  font-weight: 500;
}
