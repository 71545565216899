/* Modern Search Input */
.searchInput {
  padding: 12px 20px;
  margin-bottom: 20px;
  height: 24px;
  width: 300px;
  font-size: 15px;
  border: none;
  border-radius: 12px;
  background: #f8f9fa;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.searchInput:focus {
  box-shadow: 0 4px 20px rgba(0, 184, 230, 0.15);
  background: white;
}

.search {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

/* Flashy Table Styling */
.MuiDataGrid-root {
  background: white !important;
  border: none !important;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08) !important;
  border-radius: 16px !important;
  overflow: hidden !important;
}

.MuiDataGrid-columnHeaders {
  background: linear-gradient(135deg, #000000, #1a1a1a) !important;
  color: white !important;
  font-size: 15px !important;
  min-height: 60px !important;
  border: none !important;
}

.MuiDataGrid-columnHeader {
  border: none !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
}

.MuiDataGrid-cell {
  border-bottom: 1px solid rgba(0, 184, 230, 0.08) !important;
  padding: 16px !important;
  font-size: 14px !important;
}

.MuiDataGrid-row {
  transition: all 0.2s ease !important;
}

.MuiDataGrid-row:hover {
  background: rgba(0, 184, 230, 0.04) !important;
  transform: translateY(-1px) !important;
}

/* Modern Action Buttons */
.userListEdit {
  background: linear-gradient(135deg, #000000, #1a1a1a);
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.userListEdit:hover {
  background: linear-gradient(135deg, #00b8e6, #0099cc);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 184, 230, 0.2);
}

.userListDelete {
  color: #ff4d4d;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: 15px;
}

.userListDelete:hover {
  color: #ff1a1a;
  transform: scale(1.15);
}

/* Status Cell Styling */
.status-cell {
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 500;
  text-align: center;
  background: rgba(0, 184, 230, 0.1);
  color: #00b8e6;
}

/* Checkbox Styling */
.MuiCheckbox-root {
  color: #00b8e6 !important;
}

/* Pagination Styling */
.MuiTablePagination-root {
  color: #000000 !important;
}

.MuiTablePagination-actions button {
  color: #00b8e6 !important;
}

/* Loading State */
.MuiDataGrid-loadingOverlay {
  background: rgba(255, 255, 255, 0.9) !important;
}

/* Selection Highlight */
.MuiDataGrid-row.Mui-selected {
  background: rgba(0, 184, 230, 0.08) !important;
}

.MuiDataGrid-row.Mui-selected:hover {
  background: rgba(0, 184, 230, 0.12) !important;
}

/* Column Header Separator */
.MuiDataGrid-columnSeparator {
  display: none !important;
}

/* Custom Scrollbar */
.MuiDataGrid-window::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.MuiDataGrid-window::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.MuiDataGrid-window::-webkit-scrollbar-thumb {
  background: #00b8e6;
  border-radius: 4px;
}

.MuiDataGrid-window::-webkit-scrollbar-thumb:hover {
  background: #0099cc;
}