.userList {
    flex: 4;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
  }
  
  .userListUser {
    display: flex;
    align-items: center;
  }
  
  .userListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .userListEdit{
      border: none;
      border-radius: 10px;
      padding: 5px 10px;
      background-color: #3bb077;
      color: white;
      cursor: pointer;
      margin-right: 20px;
  }
  
  .userListDelete{
      color: red;
      cursor: pointer;
  }
  
.loadingSpinner{
  flex: 4;
  display: flex;
  padding: 20px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
}